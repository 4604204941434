import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page2401 from '../../assets/image/catalog/04_2_others/02 스마트팜 솔루션 (ict,기자재)/스마트팜-전환-컨설팅 _01.png';
import page2402 from '../../assets/image/catalog/04_2_others/02 스마트팜 솔루션 (ict,기자재)/스마트팜-전환-컨설팅 _02.png';
import page2403 from '../../assets/image/catalog/04_2_others/02 스마트팜 솔루션 (ict,기자재)/스마트팜-전환-컨설팅 _03.png';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page2401} />
        <img src={page2402} />
        <img src={page2403} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
