import React from 'react';
import { Outlet } from 'react-router-dom';
import { MainWrapper } from './NewMain.style';
import useCheckEng from '../../../util/useCheckEng';

import Navigation from './navigation/Navigation.container';
import Footer from './Footer';

function Main() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();
  // const isEng = i18n.language === 'en';

  return (
    <MainWrapper id="main" isEng={isEng}>
      <Navigation />
      <Outlet />
      <Footer />
    </MainWrapper>
  );
}

export default Main;
