import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page1701 from '../../assets/image/catalog/03 BIO_images/02 B2B 진단키트 평가_실증 서비스/Slice 4.jpg';
import page1702 from '../../assets/image/catalog/03 BIO_images/02 B2B 진단키트 평가_실증 서비스/Slice 5.jpg';
import page1703 from '../../assets/image/catalog/03 BIO_images/02 B2B 진단키트 평가_실증 서비스/Slice 6.jpg';
import page1704 from '../../assets/image/catalog/03 BIO_images/02 B2B 진단키트 평가_실증 서비스/Slice 7.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';

function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1701} />
        <img src={page1702} />
        <img src={page1703} />
        <img src={page1704} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
