import styled from '@emotion/styled';
// import mainImage from '../../assets/image/main_bg.png';
// import theme from '../../../commons/theme';
// import i18n from 'i18next';
// import mailImage from '../../assets/image/mail.svg';
// import mailImageHover from '../../assets/image/mail_hover.svg';
import circleArrow from '../../../assets/image/whiteArrow.svg';


export const MainWrapper = styled.section`
    a{
        text-decoration: none;
    }
    ul{
        list-style: none;
    }
    #navigation{
        position: sticky;
        top: 0;
        z-index: 100;
        background: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
    main {
        .mainVisual a{
            display: block;
            width :100%;
            height: 100%;
        }
        .first{
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .second{
            padding: 7.5rem 0;
            margin: 0 auto;
            h2{
                font-size: 2.875rem;
                color: #242A30;
                font-weight: 700;
                text-align: center;
                margin-bottom: 2.25rem;
            }
            > p{
                text-align: center;
                font-size: 1.75rem;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 7.75rem;
                span{  
                    color: #00D22F;
                }
            }
            .second_tab_btn{
                margin-bottom: 3.75rem;
                li{
                    display: inline-block;
                    margin-right: 2.75rem;
                    a{
                        color: #242a30;
                        font-size: 1.75rem;
                        padding: 0.625rem 1.25rem;
                        border-radius: 55px;
                        display: block;
                        cursor: pointer;
                    }
                    &.active a{
                        background: #000;
                        color: #fff;
                    }
                    &:hover a{
                        background: #000;
                        color: #fff;
                    }
                }
            }
            h4{
                font-size: 1.5rem;
                font-weight: 600;
                height: 1.875rem;
            }
            .tab_list{
                > div {
                    &.hidden{
                        display: none;
                    }
                    > ul{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 2.75rem;
                        li{
                            border-bottom: 1px solid #e0e0e2;
                            list-style: none;
                            a{
                                display: flex;
                                text-decoration: none;
                                color: #222;
                                justify-content: space-between;
                                padding: 1.875rem 1rem 3rem 0;
                                align-items: center;
                                > div{
                                    display: flex;
                                    img{
                                        width: 5.375rem;
                                        height: 5.375rem;
                                    }
                                    div{
                                        padding-left: 1.75rem;
                                        h5{
                                            font-size: 1.25rem;
                                            margin-top: 1rem;
                                            margin-bottom: 0.375rem;
                                        }
                                        p{
                                            font-size: 1rem;
                                            line-height: 150%;
                                        }
                                    }
                                }
                                span{
                                    display: block;
                                    width: 2rem;
                                    height: 2rem;
                                    background: #222222 url(${circleArrow}) no-repeat 50%;
                                    border-radius: 50%;
                                    transition: .3s;
                                    background-size: 0.75rem;
                                }
                                &:hover span{
                                    background: #00CD31 url(${circleArrow}) no-repeat 50%;
                                    background-size: 0.75rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .third{
            text-align: center;
            > p{
                font-size: 2.75rem;
                font-weight: 600;
                line-height: 150%;
                background: linear-gradient(267deg, #00CD31 4.59%, #006719 112.96%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            > div.call{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 3.5rem;
                margin-bottom: 1.25rem;
                img{
                    width: 2.25rem;
                    height: 2.25rem;
                }
                p{
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-left: 0.5rem;
                }
                a{
                    color: #00CD31;
                    font-size: 1.5rem;
                    font-weight: 600;
                    text-decoration: none;
                    margin-left: 1rem;

                }
            }
            > div.mail{
                display: flex;
                justify-content: center;
                margin-bottom: 10rem;
                a{
                    width: 12.5rem;
                    height: 3.5rem;
                    text-decoration: none;
                    border-radius: 12px;
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: #fff;
                    background: #00D22F;
                    border: 1px solid #00D22F;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 6px;
                    &:last-child{
                        margin-left: 6px;
                        margin-right: 0px;
                        color: #00D22F;
                        background: #fff;
                        border: 1px solid #00D22F;
                            
                    }   
                }
            }
        }
    }
    footer{
        background: #F8F8F8;
        padding: 8.18rem 0;
        > div{
            width: 100%;
            margin: 0 auto;
            position: relative;
            ul{
                list-style: none;
                display: flex;
                position: absolute;
                bottom: 0rem;
                right: 0;
                li{
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 63px;
                    width: 10.68rem;
                    height: 3.5rem;
                    margin-left: 1.5rem;
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                    }
                    img{
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 1rem;
                    }
                }
            }
            .logo{
                margin-bottom: 2.75rem;
                img{
                    margin-right: 2.25rem;
                    width: 14.68rem;
                    height: 2.5rem;
                }
            }
            > p{
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 1.875rem;
            }
            .ad{
                margin-bottom: 3rem;
                p{
                    color: #858587;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    span{
                        width: 1px;
                        height: 0.75rem;
                        background: #e0e0e2;
                        display: inline-block;
                        margin: 0 0.75rem;
                    }
                    &:last-child{
                        color: #717172;
                    }
                }
            }
            .privacy{
                a{
                    color: #717172;
                    font-weight: 600;
                    text-decoration: none;;
                    margin-right: 2rem;
                }
            }
        }
    }
    /* 반응형 */

    @media only screen and (max-width: 1400px) {
        & {
            min-width: initial;
        }
        .restrict-width {
            min-width: initial;
            max-width: 100%;
            padding: 0 1.25rem;
        }
        main .second{
            padding: 7.5rem 1.25rem;
        }


    }
    @media only screen and (max-width: 640px) {
        .restrict-width {
            min-width: initial;
            max-width: 100%;
        }
        main {
            .first{
                img{
                }
            }
            .second{
                max-width: 100%;
                padding: 0 1.25rem;
                margin-top: 5.25rem;
                h2{
                    font-size: 1.5rem;
                    margin-bottom: 1.25rem;
                }
                > p{
                    font-size: 1.125rem;
                    width: 13.625rem;
                    margin: 0 auto;
                    margin-bottom: 6.875rem;
                }
                .second_tab_btn{
                    white-space: nowrap;
                    overflow-x: auto;
                    margin-bottom: 1.5rem;
                    padding-bottom: 1px;
                    &::-webkit-scrollbar {
                        background-color: #f5f5f5;
                        border-radius: 10px;
                        width: 7px;
                        height: 3px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #8888;
                        border-radius: 10px;
                    }
                    li{
                        margin-right: 1.25rem;
                        a{
                            font-size: 0.875rem;
                            padding: 0.375rem 0.625rem ;
                        }
                    }
                }
                h4.mt-30{
                    margin-top: 1.875rem;
                }
                h4{
                    font-size: 1rem;
                }
                .tab_list{
                    > div {
                        > ul{
                            grid-template-columns: 1fr;
                            li{
                                &.border_none{
                                    border-bottom: 1px solid #ddd;
                                    margin-bottom: 0;
                                }
                                &:last-child{
                                    border-bottom: none;
                                }
                                a{
                                    padding: 1.125rem 0 1.5rem;
                                    > div{
                                        img{
                                            width: 3.125rem;
                                            height: 3.125rem;
                                        }
                                        div{
                                            padding-left: 0.625rem;
                                            h5{
                                                font-size: 0.75rem;
                                                margin-top: 0;
                                            }
                                            p{
                                                font-size: 0.6865rem;
                                            }
                                        }
                                    }
                                    span{
                                        width: 1.5rem;
                                        height: 1.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .third{
                padding-top: 5rem;
                > p{
                    font-size: 1.25rem;
                }
                > div.call{
                    margin-top: 2rem;
                    > img{
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                    p{
                        font-size: 1rem;
                    }
                    a{
                        font-size: 1rem;

                    }
                }
                > div.mail{
                    display: block;
                    margin-bottom: 6.875rem;
                    a{
                        font-size: 1.125rem;
                        height: 3.5rem;
                        width: 12.5rem;
                        margin: 0 auto;
                        &:last-child{
                            margin-left: 0;
                            margin: 0 auto;
                            margin-top: 0.375rem;
                        }   
                    }
                }
            }
        }
        footer{
            padding: 1.75rem 0 2.5rem;
            .restrict-width{
                padding: 0 1.25rem;
            }
            > div{
                ul{
                    position: static;
                    margin-top: 2.5rem;
                    li{
                        width: 2.75rem;
                        height: 2.75rem;
                        margin-left: 0;
                        margin-right: 0.9375rem;
                        img{
                            margin-right: 0;
                        }
                        p{
                            display: none;
                        }
                    }
                }
                .logo{
                    margin-bottom: 4.375rem;
                    img{
                        width: 6rem;
                        margin-right: 0.875rem;
                        height: auto;
                    }
                    &:last-child img{
                        width: 6.375rem;
                    }
                }
                > p{
                    font-size: 0.875rem;
                    margin-bottom: 0.875rem;
                }
                .ad{
                    margin-bottom: 6.625rem;
                    p{
                        font-size: 0.75rem;
                        margin-bottom: 0.5rem;
                        span{
                            margin: 0 0.75rem;
                        }
                        &:last-child{
                        }
                    }
                }
                .privacy{
                    a{
                        font-size: 0.75rem;
                        margin-right: 0.75rem;
                    }
                }
            }
        }
        
    }
`;