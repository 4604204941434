import styled from '@emotion/styled';
import mailImage from '../../../../assets/image/mail.svg';
import mailImageHover from '../../../../assets/image/mail_hover.svg';

export const HeaderWrapper = styled.section`
    a {
        text-decoration: none;
    }
    ul {
        list-style: none;
    }
    .pc_header {
        height: 5rem;
        width: 100%;
        position: relative;
        background: #fff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        > div{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            height: 100%;
            > .logo {
                width: 25%;
                height: fit-content;
                display: flex;
                justify-content: start;
                align-items: center;
                a {
                    width: fit-content;
                    height: 2.04rem;
                }
                img {
                    height: 100%;
                }
            }
            > ul.menu {
                width: 50%;
                display: flex;
                justify-content: stretch;
                padding: 0 3rem;
                .menu-item{
                    flex-grow: 1;
                    list-style: none;
                    &:hover{
                        a{
                            color: #00CD31;
                            cursor: pointer;
                        }
                        .depth2{
                            display: block;
                            z-index: 100;
                        }
                    } 
                    > a{
                        font-size: 1.125rem;
                        color: #242A30;
                        font-weight: 600;
                        display: block;
                        padding: 0 1rem;
                        height: 5rem;
                        line-height: 5rem;
                        text-align: center;
                    }
                    .depth2{
                        display: none;
                        background: #fff;
                        position: absolute;
                        width: 100%;
                        left:0;
                        padding-bottom: 4.06rem; 
                        top: 5rem;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                        > div {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            grid-template-rows: auto;
                            grid-gap: 2.75rem;
                            padding-top: 2.25rem;
                            height: 18.75rem;
                            > .left{
                                grid-area: 1 / 1 / 2/ 2;
                                width: 100%;
                                > h2{
                                    color: #222;
                                    font-size: 1.75rem;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 1.75rem; /* 100% */
                                    margin-bottom: 1rem;
                                }
                                > p{
                                    color: #666;
                                    font-size: 1rem;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 1.25rem; /* 125% */
                                    margin-bottom: 2rem;
                                }
                                > .tab_btn a{
                                    display: block;
                                    font-size: 1rem;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    width: 100%;
                                    border-radius: 0.5rem;
                                    padding: 0.75rem 1rem;
                                    color: #222;
                                    &.active{
                                        color: #00cd31;
                                        background: rgba(0,210,47,0.1);
                                    }
                                }
                            
                            }
                            > .tab{
                                grid-area: 1 / 2 / 2 / 5;
                                .hidden{
                                    display: none;
                                }
                                h3{
                                    color: #222;
                                    font-size: 1.5rem;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    margin-bottom: 1.5rem;
                                }
                                ul{
                                    display: grid;
                                    grid-template-columns: 1fr 1fr 1fr;
                                    column-gap: 2.75rem;
                                    li{
                                        border-bottom: 1px solid #e2e2e2;
                                        padding: 0.875rem 0 1rem;
                                        &:nth-of-type(-n+3){
                                            border-top: 1px solid #e2e2e2;
                                        }
                                        &:hover{
                                            a h4{
                                                color: #00cd31;
                                            }
                                        }
                                        a{
                                            h4{
                                                color: #222;
                                                font-size: 1rem;
                                                font-style: normal;
                                                font-weight: 600;
                                                line-height: 1.25rem; /* 125% */
                                            }
                                            p{
                                                color: #666;
                                                font-size: 0.875rem;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 1rem; /* 114.286% */
                                                margin-top: 0.5rem;
                                            }
                                        }
                                    }

                                }

                            }
                        }
                    }
                }
            }
            .membership {
                width: 25%;
                display: flex;
                justify-content: end;
                align-items: center;
                .membership_btn {
                    font-size: 1.125rem;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    img{
                        width: 1.5rem;
                    }
                }
                .inquiry_btn {
                    margin-left: 2.25rem;
                    border: 1px solid #aaa;
                    border-radius: 8px;
                    width: 7.06rem;
                    height: 2.25rem;
                    background: #fff;
                    font-size: 1rem;
                    font-weight: 600;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: #00CD31;
                    padding-right: 1rem;
                    text-align: right;
                    background: url(${mailImage}) no-repeat 0.8rem 50%;
                    background-size: 1.5rem;
                    cursor: pointer;
                    &:hover{
                        color: #fff;
                        background: #00CD31 url(${mailImageHover}) no-repeat 0.8rem 50%;
                        background-size: 1.5rem;
                        border: 1px solid #00cd31;
                    }
                }
                
            }
        }
    }
    
    /* 반응형 */
    @media only screen and (max-width: 640px) {
        .pc_header{
            display: none;
        }
        .mobile_header{
            display: block;
            &.active{
                .m_menu_list{
                    left: 0;
                }
            }
        }
        
    }
    `