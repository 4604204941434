import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page0501 from '../../assets/image/catalog/01 DX_images/05 양돈 매니저/05-양돈매니저_01.jpg';
import page0502 from '../../assets/image/catalog/01 DX_images/05 양돈 매니저/05-양돈매니저_02.jpg';
import page0503 from '../../assets/image/catalog/01 DX_images/05 양돈 매니저/05-양돈매니저_03.jpg';
import page0504 from '../../assets/image/catalog/01 DX_images/05 양돈 매니저/05-양돈매니저_04.jpg';
import page0505 from '../../assets/image/catalog/01 DX_images/05 양돈 매니저/05-양돈매니저_05.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page0501} />
        {/* <map name="image-map">
          <area target="_blank" alt="앱스토어 양돈매니저" title="" href="https://apps.apple.com/kr/app/id6444398070" shape="circle" coords="189, 428, 54"></area>
          <area target="_blank" alt="플레이스토어 양돈매니저" title="" href="https://play.google.com/store/apps/details?id=com.aidkr.dothrak&pcampaignid=web_share" shape="circle" coords="324, 427, 55"></area>
        </map> */}
        <img src={page0502} />
        <img src={page0503} />
        <a href="/sow-live">
          <img src={page0504} />
        </a>
        <a href="/fatten-live">
          <img src={page0505} />
        </a>
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
