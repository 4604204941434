import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page0601 from '../../assets/image/catalog/01 DX_images/06 육계 라이브/06 육계 라이브_01.jpg';
import page0602 from '../../assets/image/catalog/01 DX_images/06 육계 라이브/06 육계 라이브_02.jpg';
import page0603 from '../../assets/image/catalog/01 DX_images/06 육계 라이브/06 육계 라이브_03.jpg';
import page0604 from '../../assets/image/catalog/01 DX_images/06 육계 라이브/06 육계 라이브_04.jpg';
import page0605 from '../../assets/image/catalog/01 DX_images/06 육계 라이브/06 육계 라이브_05.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page0601} />
        <img src={page0602} />
        <img src={page0603} />
        <a href="/chicken-manager"><img src={page0604} /></a>
        <a href="/eco"><img src={page0605} /></a>
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
