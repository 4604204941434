import styled from '@emotion/styled';
import mailImage from '../../../../assets/image/mail.svg';
import mailImageHover from '../../../../assets/image/mail_hover.svg';

export const HeaderWrapper = styled.section`
    a {
        text-decoration: none;
    }
    ul {
        list-style: none;
    }
    .mobile_header{
        display: none;
        .m_top{
            display: flex;
            justify-content: space-between;
            height: 3.125rem;
            align-items: center;
            padding: 0 1.25rem;
            h1{
                a{
                    display: block;
                    img{
                        vertical-align: middle;
                    }
                }
            }
            button{
                background: none;
                border: none;
                img{
                    vertical-align: middle;
                }

            }
        }
        .m_menu_list{
            position: fixed;
            top: 0%;
            transition: 0.4s;
            left: 100%;
            background: white;
            width: 100%;
            height: 100%;
            z-index: 9;
            .m_menu_top{
                display: flex;
                height: 4.125rem;
                justify-content: space-between;
                align-items: center;
                padding: 0 1.25rem;
                a {
                    img{
                        vertical-align: middle;
                    }
                }
                button{
                    background: none;
                    border: none;
                    img{
                        vertical-align: middle;
                    }
                }
            }
            .m_menu{
                padding: 0 1.25rem;
                > ul{
                    position: relative;
                    > li{
                        &.active{
                            > h2 a{
                                color: #00CD31;
                            }
                            .m_depth2 {
                                display: block;
                            }
                        }
                        > h2{
                            width: 40%;
                            margin-bottom: 2.5rem;
                            font-size: 1rem;
                            line-height: 1.25rem;
                            a{
                                color: #242A30;
                                font-weight: 700;
                            }
                            .membership_btn{
                                display: flex;
                                align-items: center;
                            }
                        }
                        > div{
                        .m_depth2{
                            display: none;
                            width: 60%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            > li{
                                margin-bottom: 2.35rem;
                                > h3{
                                    font-size: 0.75rem;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                    margin-top: 0.38rem;
                                }
                                > ul{
                                    margin-top: 0.5rem;
                                    margin-bottom: 0.5rem;
                                    > li{
                                        a {
                                            display: block;
                                            font-size: 0.875rem;
                                            color: #424242;
                                            padding: 0.75rem;
                                            border-bottom: 1px solid #ddd;
                                        }
                                    }
                                    > li:last-of-type{
                                        a{
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }
                        }
                    }
                }

            }
            .m_inquiry{
                position: absolute;
                left: 1.25rem;
                bottom: 1.75rem;
                a{
                    margin-right: 1.25rem;
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: #00cd31;
                }
            }
        }
    }



    @media only screen and (max-width: 1400px) {
        & {
            min-width: initial;
        }
    }
    
    /* 반응형 */
    @media only screen and (max-width: 640px) {
        .pc_header{
            display: none;
        }
        .mobile_header{
            display: block;
            &.active{
                .m_menu_list{
                    left: 0;
                }
            }
        }
        
    }
    `