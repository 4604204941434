import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page0701 from '../../assets/image/catalog/01 DX_images/07 육계 매니저/07 육계 매니저_01.jpg';
import page0702 from '../../assets/image/catalog/01 DX_images/07 육계 매니저/07 육계 매니저_02.jpg';
import page0703 from '../../assets/image/catalog/01 DX_images/07 육계 매니저/07 육계 매니저_03.jpg';
import page0704 from '../../assets/image/catalog/01 DX_images/07 육계 매니저/07 육계 매니저_04.jpg';
import page0705 from '../../assets/image/catalog/01 DX_images/07 육계 매니저/07 육계 매니저_05.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';

function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page0701} />
        <img src={page0702} />
        <img src={page0703} />
        <a href="/chicken-live"><img src={page0704} /></a>
        <a href="/eco"><img src={page0705} /></a>
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
