import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page1301 from '../../assets/image/catalog/02 HC_images/04 팜스플랜 동물약품 (보류)/Slice 23.jpg';
import page1302 from '../../assets/image/catalog/02 HC_images/04 팜스플랜 동물약품 (보류)/Slice 24.jpg';
import page1303 from '../../assets/image/catalog/02 HC_images/04 팜스플랜 동물약품 (보류)/Slice 25.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1301} />
        <img src={page1302} />
        <img src={page1303} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
