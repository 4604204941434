import React, { useEffect } from 'react';
import { PageWrapper } from './Page.style';
import useCheckEng from '../../util/useCheckEng';
import page0101 from '../../assets/image/catalog/01 DX_images/01 비대면수의/01-비대면수의_01.jpg';
import page0102 from '../../assets/image/catalog/01 DX_images/01 비대면수의/01-비대면수의_02.jpg';
import page0103 from '../../assets/image/catalog/01 DX_images/01 비대면수의/01-비대면수의_03.jpg';
import page0104 from '../../assets/image/catalog/01 DX_images/01 비대면수의/01-비대면수의_04.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';

function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page0101} />
        {/* <map name="image-map0101">
          <area target="_blank" alt="농장주 앱스토어 다운로드" title="농장주 앱스토어 다운로드" href="https://apps.apple.com/us/app/farmvetcare-for-farmer/id6452502790" coords="188, 613, 55" shape="circle"></area>
          <area target="_blank" alt="농장주 구글스토어 다운로드" title="농장주 구글스토어 다운로드" href="https://play.google.com/store/apps/details?id=com.aidkr.meereen" coords="323, 613, 54" shape="circle"></area>
        </map> */}
        <img src={page0102} />
        <img src={page0103} />
        <img src={page0104} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
