import React, { useEffect } from 'react';
import { PageWrapper } from './Page.style';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page1901 from '../../assets/image/catalog/03 BIO_images/04 B2B 개량 품종 실증 서비스/Slice 11.jpg';
import page1902 from '../../assets/image/catalog/03 BIO_images/04 B2B 개량 품종 실증 서비스/Slice 12.jpg';
import page1903 from '../../assets/image/catalog/03 BIO_images/04 B2B 개량 품종 실증 서비스/Slice 13.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1901} />
        <img src={page1902} />
        <img src={page1903} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
