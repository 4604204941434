import React, { useEffect } from 'react';
import { PageWrapper } from './Page.style';
import useCheckEng from '../../util/useCheckEng';
import page1801 from '../../assets/image/catalog/03 BIO_images/03 B2C 유전자 검사 및 교배프로그램 서비스/Slice 8.jpg';
import page1802 from '../../assets/image/catalog/03 BIO_images/03 B2C 유전자 검사 및 교배프로그램 서비스/Slice 9.jpg';
import page1803 from '../../assets/image/catalog/03 BIO_images/03 B2C 유전자 검사 및 교배프로그램 서비스/Slice 10.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1801} />
        <img src={page1802} />
        <img src={page1803} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
