import React, { useEffect } from 'react';
import { PageWrapper } from './Page.style';
import useCheckEng from '../../util/useCheckEng';
import page1501 from '../../assets/image/catalog/02 HC_images/06 팜스플랜 엔자임/Slice 30.jpg';
import page1502 from '../../assets/image/catalog/02 HC_images/06 팜스플랜 엔자임/Slice 31.jpg';
import page1503 from '../../assets/image/catalog/02 HC_images/06 팜스플랜 엔자임/Slice 32.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1501} />
        <img src={page1502} />
        <img src={page1503} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
