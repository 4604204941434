import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page0401 from '../../assets/image/catalog/01 DX_images/04 번식돈 라이브/04 번식돈 라이브_01.jpg';
import page0402 from '../../assets/image/catalog/01 DX_images/04 번식돈 라이브/04 번식돈 라이브_02.jpg';
import page0403 from '../../assets/image/catalog/01 DX_images/04 번식돈 라이브/04 번식돈 라이브_03.jpg';
import page0404 from '../../assets/image/catalog/01 DX_images/04 번식돈 라이브/04 번식돈 라이브_04.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page0401} useMap="#image-map0401" />
        <map name="image-map0401">
          <area target="_blank" alt="모돈 라이브 앱 스토어" title="모돈 라이브 앱 스토어" href="https://apps.apple.com/us/app/id6478846805" coords="188, 426, 55" shape="circle" />
          <area target="_blank" alt="모돈 라이브 플레이 스토어" title="모돈 라이브 플레이 스토어" href="https://play.google.com/store/apps/details?id=com.aidkr.qohor" coords="323, 427, 55" shape="circle" />
        </map>
        <img src={page0402} />
        <img src={page0403} />
        <a href="/pig-manager">
          <img src={page0404} />
        </a>
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
