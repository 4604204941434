import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page1601 from '../../assets/image/catalog/03 BIO_images/01 B2B 사료 및 사료첨가제 실증 서비스/Slice 1.jpg';
import page1602 from '../../assets/image/catalog/03 BIO_images/01 B2B 사료 및 사료첨가제 실증 서비스/Slice 2.jpg';
import page1603 from '../../assets/image/catalog/03 BIO_images/01 B2B 사료 및 사료첨가제 실증 서비스/Slice 3.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1601} />
        <img src={page1602} />
        <img src={page1603} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
