import React from 'react';
import { useMediaQuery } from '@mui/material';

function NewMain() {
    const isMobile = useMediaQuery('(max-width:768px)');
    return (
        <>
            <div>This is Placeholder</div>
        </>
    );
}

export default NewMain;
