import React from 'react';
import useCheckEng from '../../../util/useCheckEng';
import ftLogo from '../../../assets/image/ft_logo.svg';
import ftLogo2 from '../../../assets/image/ft_logo2.svg';
import googleLogo from '../../../assets/image/google_logo.svg';
import appleLogo from '../../../assets/image/apple_logo.svg';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../../../commons/data';
function Main() {
  const isEng = useCheckEng();

  return (
    <footer>
      <div className='restrict-width'>
        <div className='logo'>
          <img src={ftLogo} alt="" />
          <img src={ftLogo2} alt="" />
        </div>
        <p>Copyright AIDKOREA Corp. All Rights Reserverd</p>
        <div className='ad'>
          <p>서울특별시 강남구 테헤란로 305 한국기술센터 21층</p>
          <p>사업자등록번호 : 872-88-00873<span></span>대표이사 경노겸</p>
          <p>전화: {CONTACT_PHONE}<span></span>고객문의 : {CONTACT_EMAIL}</p>
        </div>
        <div className='privacy'>
          <a href="https://www.aidkr.com/terms">서비스 이용약관</a>
          <a href="https://www.aidkr.com/privacy-policy">개인정보 처리방침</a>
        </div>
        {/* <ul>
          <li>
            <a href="#">
              <img src={googleLogo} alt="" />
              <p>Google Play</p>
            </a>
          </li>
          <li>
            <a href="#">
              <img src={appleLogo} alt="" />
              <p>App Store</p>
            </a>
          </li>
        </ul> */}
      </div>
    </footer>
  );
}

export default Main;
