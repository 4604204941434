import React from 'react';
import LaptopMainContent from '../../component/newMain/laptop/MainContent';
import MobileMainContent from '../../component/newMain/mobile/MainContent';
import { useMediaQuery } from '@mui/material';

function MainContent() {
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <>
      {isMobile ? <MobileMainContent /> : <LaptopMainContent />}
    </>
  );
}

export default MainContent;
