import React from 'react';
import LaptopMain from '../../component/newMain/laptop/NewMain.container';
import MobileMain from '../../component/newMain/mobile/NewMain.container';
import { useMediaQuery } from '@mui/material';

function NewMain() {
    const isMobile = useMediaQuery('(max-width:768px)');
    return (
        <>
            {isMobile ? <MobileMain /> : <LaptopMain />}
        </>
    );
}

export default NewMain;
