import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page2201 from '../../assets/image/catalog/04_1_distribution/03 팜스플랜 출하서비스/팜스플랜-출하서비스(농장)-1.png';
import page2202 from '../../assets/image/catalog/04_1_distribution/03 팜스플랜 출하서비스/팜스플랜-출하서비스(농장)-2.png';
import page2203 from '../../assets/image/catalog/04_1_distribution/03 팜스플랜 출하서비스/팜스플랜-출하서비스(농장)-3.png';
import page2204 from '../../assets/image/catalog/04_1_distribution/03 팜스플랜 출하서비스/팜스플랜-출하서비스(농장)-4.png';
import page2205 from '../../assets/image/catalog/04_1_distribution/03 팜스플랜 출하서비스/팜스플랜-출하서비스(농장)-5.png';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page2201} />
        <img src={page2202} />
        <img src={page2203} />
        <ContactBanner />
        <img src={page2205} useMap="#image-map-link2" />
        <map name="image-map-link2">
          <area target="" alt="양계매니저" title="양계매니저" href="/chicken-manager" coords="77,259,283,308" shape="rect" />
          <area target="" alt="팜스플랜 에코" title="팜스플랜 에코" href="/eco" coords="1036,261,1242,308" shape="rect" />
        </map>
      </div>
    </PageWrapper>
  );
}

export default Page;
