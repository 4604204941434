import mainIcon01 from '../assets/image/icon/icon_sow_live.svg';
import mainIcon02 from '../assets/image/icon/icon_fatten_live.svg';
import mainIcon03 from '../assets/image/icon/icon_chicken_live.svg';
import mainIcon04 from '../assets/image/icon/icon_farm_vet.svg';
import mainIcon05 from '../assets/image/icon/icon_eco.svg';
import mainIcon06 from '../assets/image/icon/icon_welfare.svg';
import mainIcon07 from '../assets/image/icon/icon_sales.svg';
import mainIcon08 from '../assets/image/icon/icon_pig_manager.svg';
import mainIcon09 from '../assets/image/icon/icon_chick_manager.svg';
import mainIcon10 from '../assets/image/icon/icon_diagnosis.svg';
import mainIcon11 from '../assets/image/icon/icon_custom_treat.svg';
import mainIcon12 from '../assets/image/icon/icon_pathogen.svg';
import mainIcon13 from '../assets/image/icon/icon_medicine.svg';
import mainIcon14 from '../assets/image/icon/icon_feed.svg';
import mainIcon15 from '../assets/image/icon/icon_feed_add.svg';
import mainIcon16 from '../assets/image/icon/icon_feed_verify.svg';
import mainIcon17 from '../assets/image/icon/icon_diagnosis_kit.svg';
import mainIcon18 from '../assets/image/icon/icon_gene_breed.svg';
import mainIcon19 from '../assets/image/icon/icon_enhance_breed.svg';
import mainIcon20 from '../assets/image/icon/icon_insurance.svg';
import mainIcon21 from '../assets/image/icon/icon_dist_shipping.svg';
import mainIcon22 from '../assets/image/icon/icon_farm_shipping.svg';
import mainIcon23 from '../assets/image/icon/icon_data_center.svg';
import mainIcon24 from '../assets/image/icon/icon_smart_farm.svg';

const menuTree = [
  {
    title: '디지털 전환',
    subTitle: '생산성 향상과 효율적 관리의 새로운 시작',
    tabs: [
      {
        title: '모니터링 | 방역',
        items: [
          { icon: mainIcon01, link: '/sow-live', title: '번식돈 LIVE', descShort: '분만 과정 전반을 관리하는 AI 서비스', descLong: '분만을 시작으로 지연, 완료까지\n모든 프로세스 관리 가능한 인공지능(AI) 서비스' },
          { icon: mainIcon02, link: '/fatten-live', title: '비육돈 LIVE', descShort: '돈군 성장 및 체중 추이 분석, 사료효율 관리 AI 서비스', descLong: '돈군별 성장 및 체중 추이 분석\n사료효율까지 관리 가능한 인공지능(AI) 서비스' },
          { icon: mainIcon03, link: '/chicken-live', title: '육계 LIVE', descShort: '육계 밀집도 및 이상 상황 조기 탐지, 계사 관리 AI 서비스', descLong: '양계 밀집 / 이상 상황 조기 탐지\n효율적인 계사 관리 가능한 인공지능(AI) 서비스' },
          { icon: mainIcon06, link: '/welfare-monitoring', title: '동물 복지 모니터링', descShort: '실시간 가축 복지 모니터링 AI 서비스 및 맞춤형 브랜딩 ', descLong: '가축 복지 수준을 실시간 분석 모니터링\n동물 복지 수준으로 맞춤형 브랜딩까지' },
          { icon: mainIcon05, link: '/eco', title: 'ECO(환경센서)', descShort: '환경 데이터 분석으로 가축 건강, 안정, 생산성 향상 서비스', descLong: '내부 / 외부 환경 데이터 분석을 통해\n가축의 간겅과 안정, 생산성 향상 서비스' },
        ],
      },
      {
        title: '전산관리',
        items: [
          { icon: mainIcon07, link: '/fatten-sales', title: '출하 정산 도우미', descShort: '투명하고 빠른 출하 정산 및 수익 극대화 서비스', descLong: '투명하고 빠른 출하 정산액 제공\n출하 수익 극대화까지 한번에 책임지는 서비스', },
          { icon: mainIcon08, link: '/pig-manager', title: '양돈 MANAGER', descShort: '농장 데이터 디지털 전환 및 성적·사양·사료·비용 관리 서비스', descLong: '농장 관리 데이터 손쉽게 디지털 전환\n농가 성적, 사양, 사료, 비용 관리까지' },
          { icon: mainIcon09, link: '/chicken-manager', title: '육계 MANAGER', descShort: '농장 데이터 디지털 전환 및 성적·사양·사료·비용 관리 서비스', descLong: '농장 관리 데이터 손쉽게 디지털 전환\n농가 성적, 사양, 사료, 비용 관리까지' },
        ],
      },
    ],
  },
  {
    title: '헬스케어',
    subTitle: '가축 건강과 생산성을 향상시키는 종합 서비스',
    tabs: [
      {
        title: '헬스케어',
        items: [
          { icon: mainIcon10, link: '/precision-diagnostics', title: '정밀 진단 서비스', descShort: '120만건 이상 검사 데이터 활용 수의사 정밀 진단 서비스', descLong: '120만건 이상 검사 데이터를 바탕으로\n정확하고 신속한 수의사 정밀 진단 서비스 ' },
          { icon: mainIcon11, link: '/customized-treatment', title: '농장 맞춤형 진료', descShort: '농장 맞춤형 진단과 분석으로 생산성 향상 서비스', descLong: '농장에 맞는 진단과 분석으로\n농장의 고질적인 문제 해소 & 생산성 향상 서비스' },
          { icon: mainIcon12, link: '/highly-pathogenic-disease', title: '고병원성질환 대응 프로그램', descShort: '팜스플랜의 노하우로 고병원성 및 변이 병원체 등을 해결', descLong: '기존 방법으로 해결할 수 없는 고병원성 및\n변이 병원체 등을 팜스플랜의 노하우로 해결' },
          { icon: mainIcon13, link: '/veterinary-medicine', title: '팜스플랜 동물약품', descShort: '건강한 가축 성장을 위한 예방백신 & 치료 프로그램 소개', descLong: '건강한 가축 성장을 위한\n예방 백신 & 치료 프로그램 소개 서비스' },
          { icon: mainIcon14, link: '/livestock-feed', title: '팜스플랜 사료', descShort: '생애 주기 및 상황에 맞춘 팜스플랜 사료', descLong: '가축의 생애 주기 & 상황에 맞춤 제작된\n팜스플랜 사료 서비스' },
          { icon: mainIcon15, link: '/livestock-feed-additive', title: '팜스플랜 사료첨가제', descShort: '검증된 성분으로 가축 건강과 생산성을 높이는 사료첨가제', descLong: '한국을 대표하는 검증된 성분을 첨가해\n가축의 건강을 지키고 생산성을 증대하는 팜스플랜 사료첨가제' },
          { icon: mainIcon04, link: '/farm-vet-care', title: '온라인 가축 건강 상담', descShort: '언제,어디서든 전문 수의사와 1:1 맞춤 비대면 진료 서비스', descLong: '언제 어디서나 전문 수의사와\n1:1 맞춤 상담 가능한 비대면 진료 서비스' },
        ],
      },
    ],
  },
  {
    title: '바이오',
    subTitle: '가축 건강 및 생산성 향상 서비스와 관련 제품 개발 지원 종합 서비스',
    tabs: [
      {
        title: 'B2B',
        items: [
          { icon: mainIcon16, link: '/feed-additive-validation', title: "사료 및 사료첨가제 실증 서비스", descShort: '팜스플랜 바이오 케어로 사료 및 첨가제의 실증', descLong: '팜스플랜 바이오 케어로, 사료 & 사료첨가제의\n실증 서비스를 한번에! ' },
          { icon: mainIcon17, link: '/diagnostic-kit-assessment', title: "진단키트 평가 서비스", descShort: '국가공인 병성감정기관 전문가 제공 진단키트 평가 서비스', descLong: '국가공인 병성감정기관의 전문가가 제공하는\n신뢰도 높은 진단키트 평가 서비스' },
          { icon: mainIcon18, link: '/genetic-analysis-breeding', title: "유전자 검사 및 교배프로그램", descShort: '유전자 검사와 교배 프로그램으로 농장 생산성을 높이는 서비스', descLong: '유전자 검사 및 교배 프로그램으로\n농장 생산성 증대에 도움 주는 서비스' },
          { icon: mainIcon19, link: '/enhanced-breed-verification', title: "개량 품종 실증 서비스", descShort: '팜스플랜 바이오 케어로 개량품종 실증 및 데이터 분석', descLong: '팜스플랜 바이오 케어로, 개량품종 실증 서비스와\n전문적인 데이터 분석 서비스를 한번에!' },
        ],
      },
    ],
  },
  {
    title: '유통 | 기타',
    subTitle: '건강한 생산과 안전한 출하를 위한 전문적 관리',
    tabs: [
      {
        title: '유통',
        items: [
          { icon: mainIcon20, link: '/livestock-disaster-insurance', title: '팜스플랜 가축재해보험', descShort: 'DB손해보험 X 팜스플랜 가축 전용 재해 보험', descLong: 'DB손해보험 X 팜스플랜이 함께하는\n축산 농업인의 마음을 헤아리는 가축 전용 재해 보험' },
          { icon: mainIcon21, link: '/distributor-shipping-service', title: '구매 서비스 (유통사)', descShort: '인건비 절감과 판매 수익 극대화하는 팜스플랜 출하 서비스 ', descLong: '인건비 절감과 판매 수익을 극대화 해주는\n팜스플랜 출하 서비스 ' },
          { icon: mainIcon22, link: '/farm-shipping-service', title: '출하 서비스 (농장)', descShort: '인건비 절감과 판매 수익 극대화하는 팜스플랜 출하 서비스 ', descLong: '인건비 절감과 판매 수익을 극대화 해주는\n팜스플랜 출하 서비스' },
          { icon: mainIcon23, link: '/data-center', title: '팜스플랜 데이터센터', descShort: '데이터뱅크와 동물전용 AI 모델 토탈 IT 인프라 서비스', descLong: '데이터뱅크와 동물전용 AI 모델\n토탈 IT 인프라 서비스' },
          { icon: mainIcon24, link: '/smart-farm-consulting', title: '스마트팜 전환 컨설팅', descShort: '농장에 최적화된 스마트팜 구축을 위한 단계별 전환 컨설팅', descLong: '농장에 최적화된 스마트팜 구축을 위한\n단계별 전환 컨설팅' },
        ],
      },
    ],
  },
];

export default menuTree