import React, { useState } from 'react';
// import { Link } from 'react-scroll';
// import { t } from 'i18next';
import useCheckEng from '../../../../util/useCheckEng';
import logoImage from '../../../../assets/image/farmsplan_logo_190.svg';
import arrowImage from '../../../../assets/image/header_arrow.svg';
import mLogoImage from '../../../../assets/image/m_logo.svg';
import mHomeImage from '../../../../assets/image/home_btn.svg';
import mCloseImage from '../../../../assets/image/close_btn.svg';
import mMenuImage from '../../../../assets/image/menu_btn.svg';
import * as S from './Navigation.style';
import menuItems from '../../../../commons/menu';
import { CONTACT_EMAIL, MEMBERSHIP_LINK } from '../../../../commons/data';

function NestedMenuItem({
  menuItemIndex,
  menuItem,
  activeTabIndex,
  onTabSelect,
}) {
  const { title, subTitle, tabs } = menuItem;
  const handleTabClick = (tabIndex) => {
    onTabSelect(menuItemIndex, tabIndex);
  };
  const handleTabHover = (tabIndex) => {
    onTabSelect(menuItemIndex, tabIndex);
  };


  return (
    <div className="depth2">
      <div className='restrict-width'>
        <div className='left'>
          <h2>{title}</h2>
          <p>{subTitle}</p>
          {tabs.length > 1 && (
            <div className='tab_btn'>
              {tabs.map((tab, index) => (
                <a
                  key={index}
                  href="#n"
                  className={activeTabIndex === index ? 'active' : ''}
                  onClick={() => handleTabClick(index)}
                  onMouseEnter={() => handleTabHover(index)}
                >
                  {tab.title}
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="tab">
          {tabs.map((tab, index) => (
            <div key={index} className={activeTabIndex === index ? '' : 'hidden'}>
              <h3>{tab.title}</h3>
              <ul>
                {tab.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <a href={item.link || "#n"}>
                      <h4>{item.title}</h4>
                      <p>{item.descShort}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function Navigation() {
  const isEng = useCheckEng();

  const defaultSelections = menuItems.map((_item) => 0);
  const [activeTabs, setActiveTabs] = useState(defaultSelections);

  const handleTabSelect = (menuItemIndex, tabTitle) => {
    setActiveTabs((prevState) => ({
      ...prevState,
      [menuItemIndex]: tabTitle,
    }));
  };

  return (
    <S.HeaderWrapper id="navigation" isEng={isEng}>
      {/* pc header */}
      <header className="pc_header">
        <div className='restrict-width'>
          <h1 className='logo'>
            <a href="/">
              <img src={logoImage} alt="" />
            </a>
          </h1>
          <ul className='menu'>
            {menuItems.map((item, index) => (
              <li key={index} className='menu-item'>
                <a href={item.link || "#n"}>{item.title}</a>
                {item.tabs && <NestedMenuItem
                  menuItemIndex={index}
                  menuItem={item}
                  activeTabIndex={activeTabs[index]}
                  onTabSelect={handleTabSelect}
                />}
              </li>
            ))}
          </ul>
          <div className='membership'>
            <a className="membership_btn" target="_blank" href={MEMBERSHIP_LINK} rel="noreferrer noopener">멤버십<img src={arrowImage} alt="" /></a>
            <a className="inquiry_btn" href={`mailTo:${CONTACT_EMAIL}`}>문의하기</a>
          </div>
        </div>
      </header>
    </S.HeaderWrapper>
  );
}

export default Navigation;