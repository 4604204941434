import React, { useEffect } from 'react';
import image from '../assets/image/catalog/contact.jpg';
import imageMap from 'image-map';
import { CONTACT_PHONE } from '../commons/data';


function ContactBanner() {
  // const { i18n, t } = useTranslation();
  // const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <>
      <img src={image} useMap="#image-map-contact" />
      <map name="image-map-contact">
        <area target="_blank" alt="문의하기" title="문의하기" href={`tel:${CONTACT_PHONE}`} coords="628,348,1290,447" shape="rect" />
      </map>
    </>
  );
}

export default ContactBanner;
