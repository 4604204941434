import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { PageWrapper } from './Page.style';
import { container, title, content } from '../../commons/variants';
import { t } from 'i18next';
// import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';
import page1201 from '../../assets/image/catalog/02 HC_images/03 고병원성질환/Slice 20.jpg';
import page1202 from '../../assets/image/catalog/02 HC_images/03 고병원성질환/Slice 21.jpg';
import page1203 from '../../assets/image/catalog/02 HC_images/03 고병원성질환/Slice 22.jpg';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page1201} />
        <img src={page1202} />
        <img src={page1203} />
        <ContactBanner />
      </div>
    </PageWrapper>
  );
}

export default Page;
