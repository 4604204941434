import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import bannerImage from '../../../assets/image/m_banner1.png';
import bannerImage2 from '../../../assets/image/m_banner2.svg';
import bannerKakao from '../../../assets/image/m_banner_kakao.png';
import leftArrow from '../../../assets/image/left-arrow.svg';
import styled from '@emotion/styled';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MEMBERSHIP_LINK, KAKAO_FORM_LINK} from '../../../commons/data';

const CarouselWrapper = styled.div`
  & {
    position: relative;
  }

  .custom-indicator {
    position: absolute;
    bottom: 1rem;
    width: 100%;
    height: 1rem;

    .indicator-area {
      display: flex;
      justify-content: stretch;
      align-items: center;
      height: 100%;
      color: white;

      .slide-control {
        margin-left: 1.4rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        grow: 0;
        shrink: 0;
        button {
          color: white;
          background: none;
          border: none;
          font-size: 1rem;
          cursor: pointer;
        }
        .slide-prev {
          background-image: url(${leftArrow});
          background-size: contain;
          background-repeat: no-repeat;
          height: 1rem;
          width: 1rem;
        }
        .slide-next {
          transform: rotate(180deg);
          background-image: url(${leftArrow});
          background-size: contain;
          background-repeat: no-repeat;
          height: 1rem;
          width: 1rem;
        }


        .slide-number {
          font-style: normal;
          line-height: normal;
          font-size: 0.875rem;
          .current{
            font-weight: 500;
          }
          .total{
            color: rgba(255, 255, 255, 0.80);
            font-weight: 400;
          }
        }
      }
      .line-indicator-area {
        flex:1;
        position: relative;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.50);
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0%;
          height: 100%;
          background-color: #FFF;
        }
        &.progressing:after {
          width: 100%;
          transition: width 4.7s cubic-bezier(.25,.1,.2,1) 0.5s ;
        }
      }
    }
  }
`

const CustomIndicator = ({ nextSlide, prevSlide, currentSlide, slideCount, doProgress }) => {

  const slidePercentage = (currentSlide / slideCount) * 100;

  return (
    <div className="custom-indicator">
      <div className="indicator-area restrict-width">
        <div className={`line-indicator-area ${doProgress ? 'progressing' : ''}`} ></div>
        <div className="slide-control">
          <div className="slide-number">
            <span className="current">{currentSlide + 1}</span>
            <span className="total"> / {slideCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const BannerCarousel = () => {
  let sliderRef = useRef(null);
  const nextSlide = () => { sliderRef.slickNext(); }
  const prevSlide = () => { sliderRef.slickPrev(); }
  const [currentSlide, setCurrentSlide] = useState(0);
  const [doProgress, setDoProgress] = useState(false);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => {
      setDoProgress(false);
      setCurrentSlide(next)
    },
    afterChange: (current) => {
      setDoProgress(true);
    }
  };

  useEffect(() => {
    // Trigger the progress animation when the component mounts
    setDoProgress(true);
  }, []);

  return (
    <CarouselWrapper>
      <Slider ref={slider => {
        sliderRef = slider;
      }}
        {...settings}
      >
        <div className='mainVisual'>
          <a target="_blank" href={KAKAO_FORM_LINK} rel="noreferrer noopener">
            <img src={bannerKakao} alt="Banner kakao" />
          </a>
        </div>
        <div >
          <a target="_blank" href={MEMBERSHIP_LINK} rel="noreferrer noopener">
            <img src={bannerImage} alt="Banner 1" />
          </a>
        </div>
        <div>
          <img src={bannerImage2} alt="Banner 2" />
        </div>
      </Slider>
      <CustomIndicator
        nextSlide={nextSlide}
        prevSlide={prevSlide}
        currentSlide={currentSlide}
        slideCount={3}
        doProgress={doProgress}
      />
    </CarouselWrapper>
  );
};

export default BannerCarousel;