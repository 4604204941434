import React from 'react';
import call from '../../../assets/image/call.svg';
import BannerCarousel from './BannerCarousel';
import menuTree from '../../../commons/menu';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../../../commons/data';

function newLineToBrTag(str) {
  return str.split(/(?=[\n])|(?<=[\n])/g).map((line, index) => (
    <React.Fragment key={index}>
      {line === '\n' ? <br /> : line}
    </React.Fragment>
  ));
}

function MainContent() {
  const [currentTab, setCurrentTab] = React.useState(0);
  const emailAddr = CONTACT_EMAIL;

  const copyEmailAddr = async () => {
    try {
      await navigator.clipboard.writeText(emailAddr);
      alert(`이메일 주소(${emailAddr})가 복사되었습니다`);
    } catch (err) {
      console.error('Failed to copy email address: ', err);
    }
  }


  return (
    <main className='main'>
      <section className='first'>
        <BannerCarousel />
      </section>
      <section className='second restrict-width'>
        <h2>팜스플랜 솔루션</h2>
        <p>농장의 규모, 목표, 상황에 따라 필요한 솔루션은 천차만별!<br></br> <span>팜스플랜</span>은 각자에게 꼭 맞는 솔루션을 제공합니다.</p>
        <ul className="second_tab_btn">
          {menuTree.map((menu, index) => (
            <li key={index} className={currentTab === index ? 'active' : ''}>
              <a onClick={() => setCurrentTab(index)}>{menu.title}</a>
            </li>
          ))}
        </ul>
        <div className='tab_list'>
          {menuTree.map((menu, menuIndex) => (
            <div key={menuIndex} className={currentTab !== menuIndex ? 'hidden' : ''}>
              {menu.tabs.map((tab, tabIndex) => (
                <React.Fragment key={tabIndex}>
                  <h4>{tab.title}</h4>
                  <ul>
                    {tab.items.map((item, itemIndex) => (
                      <li key={itemIndex}>
                        <a href={item.link ? item.link : '#'}>
                          <div>
                            <img src={item.icon} alt="" />
                            <div>
                              <h5>{item.title}</h5>
                              <p>{newLineToBrTag(item.descLong)}</p>
                            </div>
                          </div>
                          <span></span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </section>
      <section className='third restrict-width'>
        <p>팜스플랜팀과의 상담을 통해 <br></br>솔루션에 대해 더 자세히 알아보세요.</p>
        <div className="call">
          <img src={call} alt="" />
          <p>전화 문의</p>
          <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a>
        </div>
        <div className="mail">
          <a href={`mailTo:${emailAddr}`}>이메일 문의하기</a>
          <a href="#" onClick={copyEmailAddr}>이메일 주소 복사하기</a>
        </div>
      </section>
    </main>
  );
}

export default MainContent
