import React, { useEffect } from 'react';
import { PageWrapper } from './Page.style';
import useCheckEng from '../../util/useCheckEng';
import page2001 from '../../assets/image/catalog/04_1_distribution/01 가축재해보험/팜스플랜-가축-재해-보험-1.png';
import page2002 from '../../assets/image/catalog/04_1_distribution/01 가축재해보험/팜스플랜-가축-재해-보험-2.png';
import page2003 from '../../assets/image/catalog/04_1_distribution/01 가축재해보험/팜스플랜-가축-재해-보험-3.png';
import page2004 from '../../assets/image/catalog/04_1_distribution/01 가축재해보험/팜스플랜-가축-재해-보험-4.png';
import page2005 from '../../assets/image/catalog/04_1_distribution/01 가축재해보험/팜스플랜-가축-재해-보험-5.png';
import imageMap from 'image-map';
import ContactBanner from '../../component/ContactBanner';


function Page() {
  // const { i18n, t } = useTranslation();
  const isEng = useCheckEng();

  useEffect(() => {
    imageMap('img[usemap]')
  }, [])
  // const isEng = i18n.language === 'en';
  return (
    <PageWrapper id="main" isEng={isEng}>
      <div className="catalog-width">
        <img src={page2001} />
        <img src={page2002} />
        <img src={page2003} />
        <ContactBanner />
        <img src={page2005} useMap="#image-map-link2" />
        <map name="image-map-link2">
          <area target="" alt="양계매니저" title="양계매니저" href="/chicken-manager" coords="77,259,283,308" shape="rect" />
          <area target="" alt="팜스플랜 에코" title="팜스플랜 에코" href="/eco" coords="1036,261,1242,308" shape="rect" />
        </map>
      </div>
    </PageWrapper>
  );
}

export default Page;
