import React, { useEffect } from 'react';
import './App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import Navigation from './component/navigation/Navigation.container';
import Laptop from './component/Laptop';
import Mobile from './component/Mobile';
import NewMain from './pages/membership/NewMain';
import MainContent from './pages/membership/MainContent.js';
import Page1 from './pages/01-farm-vet-care/Page.container.js';
import Page2 from './pages/02-fatten-live/Page.container.js';
import Page3 from './pages/03-fatten-sales/Page.container.js';
import Page4 from './pages/04-sow-live/Page.container.js';
import Page5 from './pages/05-pig-manager/Page.container.js';
import Page6 from './pages/06-chicken-live/Page.container.js';
import Page7 from './pages/07-chicken-manager/Page.container.js';
import Page8 from './pages/08-welfare-monitoring/Page.container.js';
import Page9 from './pages/09-eco/Page.container.js';
import Page10 from './pages/10-precision-diagnostics/Page.container.js';
import Page11 from './pages/11-customized-treatment/Page.container.js';
import Page12 from './pages/12-highly-pathogenic-disease/Page.container.js';
import Page13 from './pages/13-veterinary-medicine/Page.container.js';
import Page14 from './pages/14-livestock-feed/Page.container.js';
import Page15 from './pages/15-feed-additive/Page.container.js';
import Page16 from './pages/16-feed-additive-validation/Page.container.js';
import Page17 from './pages/17-diagnostic-kit-assessment/Page.container.js';
import Page18 from './pages/18-genetic-analysis-breeding/Page.container.js';
import Page19 from './pages/19-enhanced-breed-verification/Page.container.js';
import Page20 from './pages/20-livestock-disaster-insurance/Page.container.js';
import Page21 from './pages/21-distributor-shipping-service/Page.container.js';
import Page22 from './pages/22-farm-shipping-service/Page.container.js';
import Page23 from './pages/23-data-center/Page.container.js';
import Page24 from './pages/24-smart-farm-consulting/Page.container.js';
import EventMain from './pages/temp/EventMain.js';
import { useMediaQuery } from '@mui/material';
import './translations/i18n.js';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Routes, Route, useLocation } from 'react-router-dom';

function App() {
    const isMobile = useMediaQuery('(max-width:768px)');
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    const { i18n } = useTranslation();

    useEffect(() => {
        const isEng = location.pathname.includes('en');
        if (isEng) i18n.changeLanguage('en');
        setLoading(false);
    }, []);

    useEffect(() => {
        document.title = t('header.title');
    });

    if (loading) return null;

    return (
        <Routes>
            {/* <Route path="/" element={
                <>
                    <Navigation />
                    {isMobile ? <Mobile /> : <Laptop />}
                </>
            }/> */}
            <Route path="/qr-page" element={<EventMain />} />
            <Route path="/" element={<NewMain />} >
                <Route index element={<MainContent />} />
                <Route path="farm-vet-care" element={<Page1 />} />
                <Route path="fatten-live" element={<Page2 />} />
                <Route path="fatten-sales" element={<Page3 />} />
                <Route path="sow-live" element={<Page4 />} />
                <Route path="pig-manager" element={<Page5 />} />
                <Route path="chicken-live" element={<Page6 />} />
                <Route path="chicken-manager" element={<Page7 />} />
                <Route path="welfare-monitoring" element={<Page8 />} />
                <Route path="eco" element={<Page9 />} />
                <Route path="precision-diagnostics" element={<Page10 />} />
                <Route path="customized-treatment" element={<Page11 />} />
                <Route path="highly-pathogenic-disease" element={<Page12 />} />
                <Route path="veterinary-medicine" element={<Page13 />} />
                <Route path="livestock-feed" element={<Page14 />} />
                <Route path="livestock-feed-additive" element={<Page15 />} />
                <Route path="feed-additive-validation" element={<Page16 />} />
                <Route path="diagnostic-kit-assessment" element={<Page17 />} />
                <Route path="genetic-analysis-breeding" element={<Page18 />} />
                <Route path="enhanced-breed-verification" element={<Page19 />} />
                <Route path="livestock-disaster-insurance" element={<Page20 />} />
                <Route path="distributor-shipping-service" element={<Page21 />} />
                <Route path="farm-shipping-service" element={<Page22 />} />
                <Route path="data-center" element={<Page23 />} />
                <Route path="smart-farm-consulting" element={<Page24 />} />
            </Route>
        </Routes>

        // 
    );
}

export default App;
