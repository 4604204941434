import React, { useState } from 'react';
// import { Link } from 'react-scroll';
// import { t } from 'i18next';
import useCheckEng from '../../../../util/useCheckEng';
import logoImage from '../../../../assets/image/logo.svg';
import arrowImage from '../../../../assets/image/header_arrow.svg';
import mLogoImage from '../../../../assets/image/m_logo.svg';
import mHomeImage from '../../../../assets/image/home_btn.svg';
import mCloseImage from '../../../../assets/image/close_btn.svg';
import mMenuImage from '../../../../assets/image/menu_btn.svg';
import * as S from './Navigation.style';
import menuItems from '../../../../commons/menu';
import { CONTACT_EMAIL, CONTACT_PHONE, MEMBERSHIP_LINK } from '../../../../commons/data';

function Navigation() {
  const isEng = useCheckEng();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <S.HeaderWrapper id="navigation" isEng={isEng}>
      {/* mobile header */}
      <header className={`mobile_header ${isMenuOpen ? 'active' : ''}`}>
        <div className='m_top'>
          <h1><a href="/"><img src={mLogoImage} alt="팜스플랜" /></a></h1>
          {/* button 클릭시 mobile_header 클래스에 active 추가 */}
          <button onClick={() => setIsMenuOpen(true)}>
            <img src={mMenuImage} alt="메뉴 열기" />
          </button>
        </div>
        <div className={`m_menu_list ${isMenuOpen ? '' : 'hidden'}`}>
          <div className="m_menu_top">
            <a href="/"><img src={mHomeImage} alt="홈으로" /></a>
            {/* button 클릭시 mobile_header 클래스에 active 삭제 */}
            <button onClick={() => setIsMenuOpen(false)}>
              <img src={mCloseImage} alt="메뉴 닫기" />
            </button>
          </div>
          <div className="m_menu">
            <ul>
              {menuItems.map((depth1, index) => (
                <li key={index} className={activeTab === index ? 'active' : ''}>
                  <h2><a onClick={() => handleTabClick(index)}>{depth1.title}</a></h2>
                  <div>
                    <ul className="m_depth2">
                      {depth1.tabs.map((depth2, index) => (
                        <li key={index}>
                          <h3>{depth2.title}</h3>
                          <ul>
                            {depth2.items.map((item, index) => (
                              <li key={index}>
                                <a href={item.link || '#'}>{item.title}</a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              ))}
              <li className="">
                <h2><a className="membership_btn" target="_blank" href={MEMBERSHIP_LINK} rel="noreferrer noopener">멤버십<img src={arrowImage} alt="" /></a></h2>
              </li>
            </ul>
          </div>
          <div className='m_inquiry'>
            <a href={`mailTo:${CONTACT_EMAIL}`}>메일 문의</a>
            <a href={`tel:${CONTACT_PHONE}`}>전화 문의</a>
          </div>
        </div>
      </header>
    </S.HeaderWrapper>
  );
}

export default Navigation;