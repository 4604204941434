import React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import logimage from '../../assets/image/farmsplan_logo.png';
import krimage from '../../assets/image/kr_btn.png';
import engimage from '../../assets/image/eng_btn.png';
import * as S from './Navigation.style';
import { Link } from 'react-scroll';
import { useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useCheckEng from '../../util/useCheckEng';

function HideOnScroll({ children, window }) {
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function Navigation(props) {
    const breakPoint = useMediaQuery('(max-width:768px)');
    const { i18n, t } = useTranslation();
    const isEng = useCheckEng();

    return (
        <HideOnScroll {...props}>
            <S.Navbar>
                <S.NavbarWrapper>
                    <Link to="main" smooth={true} offset={-100} duration={1500}>
                        <S.Logo>
                            <img src={logimage} alt="logo" />
                        </S.Logo>
                    </Link>
                    <S.MenuWrapper>
                        {!breakPoint && (
                            <S.MenuPtag
                                isEng={isEng}
                                width="143px"
                            >
                                {t('header.service')}
                            </S.MenuPtag>
                        )}
                        <Link to="apply" smooth={true} duration={1500}>
                            <S.MenuButton width="100px" outLine={true} fontColor={true}>
                                {t('header.free')}
                            </S.MenuButton>
                        </Link>
                        {i18n.language === 'ko' ? (
                            <Button onClick={() => i18n.changeLanguage('en')}>
                                <img src={engimage} alt="logo" />
                            </Button>
                        ) : (
                            <Button onClick={() => i18n.changeLanguage('ko')}>
                                <img src={krimage} alt="logo" />
                            </Button>
                        )}
                    </S.MenuWrapper>
                </S.NavbarWrapper>
            </S.Navbar>
        </HideOnScroll>
    );
}

export default Navigation;
